import { useState } from 'react';
import { NextRouter } from 'next/router';
import ReferralCodeConfig from '../../config/ReferralCodeConfig';
import { ReferralCodeStore } from '../../domain/store/ReferralCodeStore';

export const useReferralCodeStore = (router: NextRouter): ReferralCodeStore => {
  const { query } = router;
  const [service, setService] = useState({
    loading: false,
    isValidCode: false,
    isNotValidCode: false,
    codeNotFound: false,
    codeExpired: false,
  });

  const paramReferralCode = query[ReferralCodeConfig.ParamName]
    ? String(query[ReferralCodeConfig.ParamName])
    : undefined;

  const initValidation = () => {
    setService({
      loading: true,
      isValidCode: false,
      isNotValidCode: false,
      codeNotFound: false,
      codeExpired: false,
    });
  };

  const setSuccess = () => {
    setService({
      loading: false,
      isValidCode: true,
      isNotValidCode: false,
      codeNotFound: false,
      codeExpired: false,
    });
  };

  const setError = errorCode => {
    setService({
      loading: false,
      isValidCode: false,
      isNotValidCode: true,
      codeNotFound: errorCode === 'ERROR_CODE_NOT_FOUND',
      codeExpired: errorCode === 'ERROR_CODE_EXPIRED',
    });
  };

  const restore = () => {
    setService({
      loading: false,
      isValidCode: false,
      isNotValidCode: false,
      codeNotFound: false,
      codeExpired: false,
    });
  };

  const getTemporalCode = () =>
    localStorage.getItem(ReferralCodeConfig.LocalStorageKey) || undefined;
  const setTemporalCode = (code: string) =>
    localStorage.setItem(ReferralCodeConfig.LocalStorageKey, code);
  const clearTemporalCode = () =>
    localStorage.removeItem(ReferralCodeConfig.LocalStorageKey);

  const referralCode = getTemporalCode();
  const isThereReferralCode = !!referralCode;

  const persistence = {
    isThereReferralCode,
    referralCode,
    set: setTemporalCode,
    clear: clearTemporalCode,
  };

  return {
    paramReferralCode,

    service,

    restore,
    initValidation,
    setSuccess,
    setError,

    persistence,
  };
};
