/* eslint-disable consistent-return */
export const useReferralCodeRules = (
  onSubmitReferralCode: (_: string) => Promise<any>,
  onBlurReferralCode: (_: any) => void
) => ({
  required: false,
  validate: async (code: string) => {
    if (onSubmitReferralCode) {
      await onSubmitReferralCode(code);
    }

    return true;
  },
  onBlur: (event: any): void => {
    if (onBlurReferralCode) {
      return onBlurReferralCode(event);
    }
  },
});
