import { Box } from '@material-ui/core';
import { Typography } from '../../../../src/design-system/atoms';
import Link from '../../../shared/Link';

interface TermsAndConditionsProps {
  countryId: 'MX' | 'CL';
}

const LINKS = {
  CL: {
    TERMS: 'https://www.xepelin.com/terminos-y-condiciones/',
    PRIVACY: 'https://www.xepelin.com/politica-de-privacidad/',
  },
  MX: {
    TERMS: 'https://www.xepelin.com/mx/terminos-y-condiciones',
    PRIVACY: 'https://www.xepelin.com/mx/aviso-de-privacidad',
  },
};

export function TermsAndConditions({ countryId }: TermsAndConditionsProps) {
  const termsLink = LINKS[countryId]?.TERMS || LINKS.CL.TERMS;
  const privacyLink = LINKS[countryId]?.PRIVACY || LINKS.CL.PRIVACY;
  return (
    <Typography
      component="div"
      gutterBottom
      variant="subtitle1"
      color="textSecondary"
    >
      Al registrarte estás aceptando los{' '}
      <Link color="textSecondary" href={termsLink} target="_blank">
        <Box component="span" fontWeight="fontWeightMedium">
          Términos y Condiciones
        </Box>
      </Link>{' '}
      y{' '}
      <Link color="textSecondary" href={privacyLink} target="_blank">
        <Box component="span" fontWeight="fontWeightMedium">
          Políticas de Privacidad
        </Box>
      </Link>
    </Typography>
  );
}
