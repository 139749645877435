import { NextRouter } from 'next/router';

/**
 * Obtains a default set of registration values from the path's query parameters.
 */
export function useDefaultRegistrationValues(
  router: NextRouter,
  countryId: string
) {
  // router.query is of type (string | string[]). We define this function to avoid code duplication when
  // extracting a single string value from the query parameters. When value is string[], we return the
  // first element or the empty string when it's empty.
  const getSingleValue = (value: string | string[]) =>
    (Array.isArray(value) ? value[0] : value) || '';

  const defaultEmail = getSingleValue(router.query.email);
  const defaultFirstname = getSingleValue(router.query.firstname);
  const defaultLastname = getSingleValue(router.query.lastname);
  const defaultPhonenumber = getSingleValue(router.query.phone);
  const defaultCompany = getSingleValue(router.query.company);
  const defaultReferralCode = getSingleValue(router.query.referralCode);

  // The default business identifier is chosen according to the countryId parameter
  let defaultIdentifier: string;
  switch (countryId) {
    case 'CL':
      defaultIdentifier = getSingleValue(router.query.rut_de_la_empresa);
      break;

    case 'MX':
      defaultIdentifier = getSingleValue(router.query.rfc_de_la_empresa);
      break;

    default:
      defaultIdentifier = '';
      break;
  }

  // Compute a default fullname value ensuring we don't end up with a leading or trailing whitespace.
  // TODO: Find a more graceful way.
  let defaultFullname = defaultFirstname;
  if (defaultFirstname && defaultLastname) {
    defaultFullname += ' ';
  }
  defaultFullname += defaultLastname;

  return {
    defaultEmail,
    defaultFirstname,
    defaultLastname,
    defaultFullname,
    defaultPhonenumber,
    defaultCompany,
    defaultIdentifier,
    defaultReferralCode,
  };
}
