/* eslint no-console: ["error", { allow: ["warn", "log"] }] */
import tracker from '../../../../src/helpers/tracker';
import { EventsTrackerRepository } from '../../domain/repository/EventsTrackerRepository';
import { EventType } from './EventType';

export const useEventsTracker = (): EventsTrackerRepository => {
  const sendEvent = (type: EventType, payload: any = {}) => {
    try {
      tracker.event(type, payload);
    } catch (e) {
      console.log('Tracker', e.message);
    }
  };

  return {
    sendEvent,
  };
};
