import { useRouter } from 'next/router';
import {
  Avatar,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../../../src/helpers/constants';

export const useStyles = makeStyles({
  countryFlagMenuItem: {
    justifyContent: 'center',
  },
  countryFlagAvatar: {
    width: 24,
    height: 24,
  },
});

export function CountryFlagSelector() {
  const classes = useStyles();
  const router = useRouter();

  const countryId = process.env.NEXT_PUBLIC_COUNTRY_ID;

  const handleCountryChange = () => {
    router.push(`${process.env.NEXT_PUBLIC_OTHER_CLIENT}/${router.pathname}`);
  };
  return (
    <FormControl variant="outlined" size="small" margin="dense">
      <Select value={countryId} onChange={handleCountryChange}>
        <MenuItem
          value={COUNTRY_CODE_CL}
          className={classes.countryFlagMenuItem}
        >
          <Avatar
            className={classes.countryFlagAvatar}
            src={`/assets/locales/${COUNTRY_CODE_CL}/flag.svg`}
          />
        </MenuItem>
        <MenuItem
          value={COUNTRY_CODE_MX}
          className={classes.countryFlagMenuItem}
        >
          <Avatar
            className={classes.countryFlagAvatar}
            src={`/assets/locales/${COUNTRY_CODE_MX}/flag.svg`}
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
}
