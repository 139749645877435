import React, { useEffect, useState, VFC } from 'react';
import { useRouter } from 'next/router';
import Alert from '@material-ui/lab/Alert';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { Button, TextField, makeStyles, Typography } from '@material-ui/core';

import { UnifiedRegisterData } from '@features/auth/domain/entity/UnifiedRegisterData';
import { Spacer } from '../../../shared';
import { useTranslation } from '../../../i18n/infra/useTranslation';
import { ReferralCodeData } from '../../domain/entity/ReferralCodeData';
import { ReferralCodeStore } from '../../domain/store/ReferralCodeStore';
import { useReferralCodeStore } from '../../infra/store/useReferralCodeStore';
import useValidateReferralCode from '../../adapters/Register/useValidateReferralCode';
import { useReferralCodeRepository } from '../../infra/repositories/useReferralCodeRepository';
import { useReferralCodeRules } from '../../../../src/helpers/validations/rules/useReferralCodeRules';

const useButtonStyles = makeStyles({
  button: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
    textTransform: 'none',
  },
});

interface ReferralCodeFormProps {
  isUnifiedView?: boolean;
  hookForm: UseFormReturn<UnifiedRegisterData, object>;
}

function GetReferralCodeErrorMessage(
  t: (text: string) => string,
  referralCodeStore: ReferralCodeStore
) {
  const { service } = referralCodeStore;
  const { isValidCode, codeNotFound, codeExpired, isNotValidCode } = service;
  if (isValidCode)
    return {
      type: 'success',
      message: t('NOTIFICATION_REFERRAL_CODE_VALID'),
    };
  if (codeNotFound)
    return {
      type: 'error',
      message: t('NOTIFICATION_REFERRAL_CODE_NOT_FOUND'),
    };
  if (codeExpired)
    return {
      type: 'error',
      message: t('NOTIFICATION_REFERRAL_CODE_EXPIRED'),
    };
  if (isNotValidCode)
    return {
      type: 'error',
      message: t('NOTIFICATION_REFERRAL_CODE_INVALID'),
    };
  return null;
}

const ReferralCodeForm: VFC<ReferralCodeFormProps> = function ReferralCodeForm({
  isUnifiedView,
  hookForm,
}) {
  const buttonStyles = useButtonStyles();

  const router = useRouter();
  const { t } = useTranslation();

  const { setValue: setValueForm, getValues } = hookForm;

  const referralCodeStore = useReferralCodeStore(router);
  const referralCodeRepository = useReferralCodeRepository();

  const [initialViewRC, setInitialViewRC] = useState(false);
  const [showReferralCode, setShowReferralCode] = useState(false);

  const handleClickShowReferralCode = () =>
    setShowReferralCode(!showReferralCode);

  const formData = getValues?.() || ({} as UnifiedRegisterData);

  const { onValidateCode } = useValidateReferralCode({
    referralCodeStore,
    referralCodeRepository,
    unifiedData: { ...formData },
  });

  const { control: referralCodeDataControl, setValue } =
    useForm<ReferralCodeData>({
      mode: 'onBlur',
    });

  useEffect(() => {
    if (referralCodeStore.paramReferralCode && !initialViewRC) {
      setShowReferralCode(true);
      setInitialViewRC(true);
    }
  }, [referralCodeStore.paramReferralCode, initialViewRC]);

  useEffect(() => {
    if (!initialViewRC) return;
    setValue('code', referralCodeStore.paramReferralCode, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [initialViewRC, referralCodeStore.paramReferralCode, setValue]);

  useEffect(() => {
    if (showReferralCode) return;
    const { loading, isNotValidCode, isValidCode } = referralCodeStore.service;
    if (!loading && !isNotValidCode && !isValidCode) return;

    setValue('code', '');
    setValueForm('referralCode', '');

    referralCodeStore.restore();
    referralCodeStore.persistence.clear();
  }, [showReferralCode, referralCodeStore, setValue, setValueForm]);

  const getErrorMessage = () => {
    return GetReferralCodeErrorMessage(t, referralCodeStore);
  };

  const referralCodeHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    let referralCode = event.target?.value || '';
    referralCode = getErrorMessage() ? '' : referralCode;
    setValueForm('referralCode', referralCode);
  };

  const referalCodeRules = useReferralCodeRules(
    onValidateCode,
    referralCodeHandler
  );

  let referralCodeErrorMessage = null;
  if (showReferralCode) {
    referralCodeErrorMessage = getErrorMessage();
  }

  const activeTextForButton = isUnifiedView
    ? '¿Tienes una invitación?'
    : 'Agregar código de invitación';
  const textForButton = showReferralCode
    ? 'No agregar código de invitación'
    : activeTextForButton;
  return (
    <>
      {showReferralCode ? (
        <Controller
          name="code"
          control={referralCodeDataControl}
          rules={referalCodeRules}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              InputProps={{
                autoComplete: 'off',
              }}
              variant="outlined"
              placeholder={t('PLACEHOLDER_REFERRAL_CODE')}
              label={t('LABEL_REFERRAL_CODE')}
              type="text"
              fullWidth
              error={
                (fieldState.isTouched && !!fieldState.error) ||
                referralCodeStore.service.isNotValidCode
              }
              helperText={fieldState.isTouched && fieldState.error?.message}
              disabled={referralCodeStore.service.loading}
            />
          )}
        />
      ) : null}
      {showReferralCode ? <Spacer spacing={1} /> : null}
      {referralCodeErrorMessage ? (
        <>
          <Alert severity={referralCodeErrorMessage.type}>
            <Typography variant="subtitle2">
              {referralCodeErrorMessage.message}
            </Typography>
          </Alert>
          <Spacer spacing={1} />
        </>
      ) : null}
      <Button
        type="button"
        color={isUnifiedView ? 'primary' : 'default'}
        className={!isUnifiedView ? buttonStyles.button : ''}
        onClick={handleClickShowReferralCode}
        disabled={referralCodeStore.service.loading}
      >
        {isUnifiedView ? (
          <u>
            <Typography variant="body2">{textForButton}</Typography>
          </u>
        ) : (
          textForButton
        )}
      </Button>
    </>
  );
};

ReferralCodeForm.defaultProps = {
  isUnifiedView: false,
};

export default ReferralCodeForm;
