import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useRouter } from 'next/dist/client/router';
import { isMobile } from 'react-device-detect';
import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { UnifiedRegisterData } from '@features/auth/domain/entity/UnifiedRegisterData';
import { useTranslation } from '../../../i18n/infra/useTranslation';
import useRegister from '../../adapters/Register/useRegister';
import { TermsAndConditions } from '../common';
import { useAuthRepository } from '../../infra/repositories/useAuthRepository';
import { useEventsTracker } from '../../infra/eventHandlers/useEventsTracker';
import { useAuthStore } from '../../infra/store/useAuthStore';
import { usePageStyles } from '../common/styles';
import { usePasswordRules } from '../../../../src/helpers/validations/rules/usePasswordRules';
import { useEmailRules } from '../../../../src/helpers/validations/rules/useEmailRules';
import ReferralCodeForm from './ReferralCodeForm';
import { urls } from '../../../libs/urls';
import { Link, LoadingButton, Spacer } from '../../../shared';
import { CountryFlagSelector } from '../common/CountryFlagSelector';
import { useSubmitError } from '../../infra/hooks/useSubmitError';
import { Typography } from '../../../../src/design-system/atoms';
import useRedirectWhenRegistered from './hooks/useRedirectWhenRegistered';

import { useDefaultRegistrationValues } from './utils';

function Register() {
  const { t } = useTranslation();
  const classes = usePageStyles();

  const authStore = useAuthStore();
  const authRepository = useAuthRepository();
  const router = useRouter();

  const eventsTracker = useEventsTracker();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  // Get the default registration values from the path's query parameters to prefill form inputs.
  const { defaultEmail } = useDefaultRegistrationValues(
    router,
    authStore.countryId
  );

  const { isLoading, isAuthError, onSubmit } = useRegister({
    authRepository,
    authStore,
    router,
    eventsTracker,
  });
  const { onSubmitError, submitError } = useSubmitError();

  const hookForm = useForm<UnifiedRegisterData>({
    mode: 'onChange',
  });

  const { control, handleSubmit, setError, setValue } = hookForm;

  // When the router is ready we prefill the inputs with registration the default registration values.
  // We are not using either useForm, Controller or TextField's defaultValue property because they are only set once and
  // router.query is empty on first render, making them always empty. We need to wait for router.isReady and change the
  // values dynamically.
  //
  // (see: https://stackoverflow.com/questions/61040790/userouter-withrouter-receive-undefined-on-query-in-first-render)
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    // Change input values
    setValue('email', defaultEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    if (eventsTracker) {
      setTimeout(() => {
        eventsTracker.sendEvent('NEW_REGISTER_ON_LOAD', {
          source: 'MULTI_STEP_REGISTER',
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // todo: fix bug when people repeat this error and is AuthError continues
    if (isAuthError) {
      setError('password', {
        message: 'No es posible crear usuario con estas credenciales',
      });
    }
  }, [isAuthError, setError]);

  useRedirectWhenRegistered(authStore);

  const emailRules = useEmailRules();
  const passwordRules = usePasswordRules();

  return (
    <Box maxWidth="420px" textAlign="center">
      <CountryFlagSelector />
      <Spacer spacing={2} />
      <Typography component="div" variant="h3" className={classes.title}>
        <Box fontWeight="fontWeightMedium">Regístrate en Xepelin</Box>
      </Typography>

      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit, onSubmitError)}
      >
        <Controller
          name="email"
          rules={emailRules}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              autoFocus={!isMobile}
              variant="outlined"
              placeholder={t('PLACEHOLDER_EMAIL')}
              label={t('LABEL_EMAIL')}
              value={field.value || ''}
              type="text"
              fullWidth
              error={
                (fieldState.isTouched || !!submitError) && !!fieldState.error
              }
              helperText={
                (fieldState.isTouched || !!submitError) &&
                (fieldState.error?.message ||
                  (fieldState.error?.type === 'validate'
                    ? t('ERROR_INVALID_EMAIL')
                    : ''))
              }
            />
          )}
        />
        <Spacer spacing={2} />
        <Controller
          name="password"
          control={control}
          rules={passwordRules}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              name="password"
              value={field.value || ''}
              type={showPassword ? 'text' : 'password'}
              label={t('LABEL_PASSWORD')}
              placeholder={t('PLACEHOLDER_PASSWORD')}
              error={
                (fieldState.isTouched || submitError) && !!fieldState.error
              }
              helperText={
                (fieldState.isTouched || submitError) &&
                (fieldState.error?.message ||
                  (fieldState.error?.type === 'validate'
                    ? t('ERROR_INVALID_PASSWORD')
                    : ''))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t('HELPER_PASSWORD_VISIBILITY_ICON')}
                      edge="end"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Spacer spacing={2} />
        <ReferralCodeForm hookForm={hookForm} />
        <TermsAndConditions countryId={authStore.countryId} />
        <Spacer spacing={1} />
        {authStore.authError ? (
          <>
            <Alert severity="error">{t(authStore.authError)}</Alert>
            <Spacer spacing={2} />
          </>
        ) : null}
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          isLoading={isLoading}
        >
          Siguiente
        </LoadingButton>
      </form>
      <Typography variant="body2" className={classes.mb2}>
        ¿Ya tienes tu cuenta?{' '}
        <Link href={{ pathname: urls.login, query: router.query }}>
          Ingresar
        </Link>
      </Typography>

      <Link
        variant="body2"
        href={{ pathname: urls.forgotPassword, query: router.query }}
      >
        Recuperar acceso a mi cuenta
      </Link>
    </Box>
  );
}

export default Register;
