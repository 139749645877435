import jwtDecode from 'jwt-decode';
import { NextRouter } from 'next/router';
import { AuthError } from '../domain/AuthError';
import { RegisterData } from '../domain/RegisterData';
import { AuthRepository } from '../domain/AuthRepository';
import { AuthStore } from '../domain/AuthStore';
import { RegisterDataMap } from '../infra/mappers/registerData.map';
import { EventsTrackerRepository } from '../domain/repository/EventsTrackerRepository';

export async function registerUseCase({
  registerData,
  authRepository,
  authStore,
  eventsTracker,
}: {
  registerData: RegisterData;
  authRepository: AuthRepository;
  authStore: AuthStore;
  router: NextRouter;
  eventsTracker: EventsTrackerRepository;
}) {
  if (authStore.isLoading) {
    return;
  }
  authStore.startLoading();
  authStore.clearAuthError();

  try {
    await authRepository.register(registerData);
    const loginCredential = RegisterDataMap.toLogin(registerData);
    const accessToken = await authRepository.login(loginCredential);
    const tokenInfo: { data: any } = jwtDecode(accessToken.value);
    const { user } = tokenInfo.data;
    user.email = registerData.email;
    eventsTracker.sendEvent('NEW_USER_CREATION', {
      user,
      source: 'MULTI_STEP_REGISTER',
    });
    authStore.saveAccessToken(accessToken);
  } catch (error) {
    if (error instanceof AuthError) {
      authStore.setAuthError(error.message);
    }
  } finally {
    authStore.stopLoading();
  }
}
