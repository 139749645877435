import axios from '../../../../src/helpers/axios';
import { AuthError } from '../../domain/AuthError';

import { ReferralCodeData } from '../../domain/entity/ReferralCodeData';
import { ReferralCodeRepository } from '../../domain/repository/ReferralCodeRepository';

const urlEndpoint = `${process.env.NEXT_PUBLIC_REFERRAL_CODE_URL}/onboarding/register`;
const headers = {
  'x-api-key': process.env.NEXT_PUBLIC_REFERRAL_CODE_API_KEY || '',
  'Content-Type': 'application/json',
};

export const useReferralCodeRepository = (): ReferralCodeRepository => {
  const register = (
    referralCodeData: ReferralCodeData,
    isValidating: boolean = false
  ) =>
    axios
      .post(
        urlEndpoint,
        {
          identifier: referralCodeData.identifier || '',
          country: String(process.env.NEXT_PUBLIC_COUNTRY_ID || ''),
          validating: isValidating,
          code: '',
          referralCode: referralCodeData.code,
          test: false,
          UTMs: {},
        },
        {
          headers,
        }
      )
      .then(response => {
        if (!response.data) return response.data;
        return Boolean(response.data && response.data.status);
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        throw new AuthError(errorCode);
      });

  const validate = (referralCodeData: ReferralCodeData) =>
    register(referralCodeData, true);

  return {
    register,
    validate,
  };
};
