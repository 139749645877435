import { AuthStore } from '@features/auth/domain/AuthStore';
import { urls } from '@features/libs/urls';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useRedirectWhenRegistered = (authStore: AuthStore) => {
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (authStore.isAuthenticated) {
      delete router.query;
      router.push({ pathname: urls.completeRegister, query });
    }
  }, [authStore.isAuthenticated, router, query]);
};

export default useRedirectWhenRegistered;
