import { makeStyles } from '@material-ui/core';

export const useCarouselStyles = makeStyles({
  container: {
    display: 'inline-block',
    width: '100%',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  title: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  subtitle: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  skipButton: {
    display: 'flex',
    alignSelf: 'center',
    border: 'none',
    backgroundColor: 'inherit',
    color: 'white',
    '&:hover': {
      color: 'white',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
});
