import { ReferralCodeData } from '../domain/entity/ReferralCodeData';
import { ReferralCodeStore } from '../domain/store/ReferralCodeStore';
import { ReferralCodeRepository } from '../domain/repository/ReferralCodeRepository';

type UseCaseProps = {
  referralCodeData: ReferralCodeData;
  referralCodeRepository: ReferralCodeRepository;
  referralCodeStore: ReferralCodeStore;
};

export async function validateReferralCodeUseCase({
  referralCodeData,
  referralCodeRepository,
  referralCodeStore,
}: UseCaseProps) {
  if (!referralCodeData.code) return;

  referralCodeStore.initValidation();
  referralCodeStore.persistence.clear();

  try {
    const validCode = await referralCodeRepository.validate(referralCodeData);
    if (validCode) {
      referralCodeStore.setSuccess(referralCodeData.code);
      referralCodeStore.persistence.set(referralCodeData.code);
    } else {
      throw new Error();
    }
  } catch (e) {
    referralCodeStore.setError(e.message);
  }
}
