import { NextRouter } from 'next/router';
import { registerUseCase } from '../../useCases/RegisterUseCase';
import { AuthRepository } from '../../domain/AuthRepository';
import { AuthStore } from '../../domain/AuthStore';
import { EventsTrackerRepository } from '../../domain/repository/EventsTrackerRepository';
import { RegisterData } from '../../domain/RegisterData';

function useRegister({
  authRepository,
  authStore,
  router,
  eventsTracker,
}: {
  authRepository: AuthRepository;
  authStore: AuthStore;
  router: NextRouter;
  eventsTracker: EventsTrackerRepository;
}) {
  const onSubmit = async ({ email, name, password }) => {
    if (authStore.isLoading) {
      return;
    }

    const registerData: RegisterData = { email, password, name };

    await registerUseCase({
      registerData,
      authStore,
      authRepository,
      router,
      eventsTracker,
    });
  };

  return {
    isLoading: authStore.isLoading,
    isAuthError: authStore.isAuthError,
    onSubmit,
  };
}

export default useRegister;
