import { UnifiedRegisterData } from '@features/auth/domain/entity/UnifiedRegisterData';
import { ReferralCodeData } from '../../domain/entity/ReferralCodeData';
import { ReferralCodeStore } from '../../domain/store/ReferralCodeStore';
import { ReferralCodeRepository } from '../../domain/repository/ReferralCodeRepository';
import { validateReferralCodeUseCase } from '../../useCases/ValidateReferralCodeUseCase';

function useValidateReferralCode({
  referralCodeStore,
  referralCodeRepository,
  unifiedData,
}: {
  referralCodeStore: ReferralCodeStore;
  referralCodeRepository: ReferralCodeRepository;
  unifiedData?: UnifiedRegisterData;
}) {
  const onValidateCode = async (code: string) => {
    if (!code || code === '') {
      referralCodeStore.persistence.clear();
      return;
    }

    const identifier = unifiedData?.identifier || '';
    const referralCodeData: ReferralCodeData = { code, identifier };

    await validateReferralCodeUseCase({
      referralCodeData,
      referralCodeRepository,
      referralCodeStore,
    });
  };

  return { onValidateCode };
}

export default useValidateReferralCode;
